import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from "../images/72cea1c1-0e95-4ed2-9778-c854b4e4899f-copy.jpg";
import plane from "../images/Plane_vector_img.png";

import img1 from '../images/Building_sec3.jpg';
import img2 from '../images/Camera_vector_img.png';
import img3 from '../images/Gadgets_vector_img.png';
import img4 from '../images/Photographer_sec3.jpg';
import img6 from '../images/Sec2_bacground.jpg';
import img7 from '../images/Sec2_vector_img.png';
import img8 from '../images/Sec7_img1.jpg';
import img9 from '../images/Sec7_img2.jpg';
import img10 from '../images/Sec7_img3.jpg';
import img11 from '../images/Swimmers_sec2.jpg';
import img12 from '../images/banner_image.png';
import img13 from '../images/design_element_Tree.png';
import img14 from '../images/design_element_sec2.png';
import img15 from '../images/destination.png';
import img16 from '../images/payment_cards.png';
import img17 from '../images/skynews-heathrow-british-airways_6500614.jpg';
import img18 from '../images/star-rating.png';
import img19 from '../images/the_savoy_hotel_luxury_wedding_flowers_larry_walshe_studios.jpg';
import img20 from '../images/wallet-passes-app.png';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from 'react-autocomplete';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'flight', // Default active tab
      flightForm: {
        from: '',
        to: '',
        start: null,
        return: null,
        adults: "",
        child: "",
        infants: "",
        class: 'Economy',
      },
      hotelForm: {
        destination: '',
        checkin: null,
        checkout: null,
        rooms: "",
        adults: "",
        children: "",
        name: '',
        email: '',
        phone: '',
      },
      successMessage: '',
      errors: {},
    };
  }

  handleTabSwitch = (tab) => {
    this.setState({ activeTab: tab });
  };

  handleInputChange = (form, event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      [form]: {
        ...prevState[form],
        [name]: value,
      },
    }));
  };

  handleDateChange = (form, date, field) => {
    this.setState((prevState) => ({
      [form]: {
        ...prevState[form],
        [field]: date,
      },
    }));
  };

  handleSubmitFlight = () => {
    const { flightForm } = this.state;
    // Validate and submit flight form
    if (this.validateFlightForm()) {
      // Call API for flight search
      console.log('Flight Search API call with:', flightForm);
      // Redirect to flight results (or handle API response as needed)
      window.location.href = "/flight-results"; // Example redirect
    }
  };

  handleSubmitHotel = () => {
    const { hotelForm } = this.state;
    // Validate and submit hotel form
    if (this.validateHotelForm()) {
      // Call API to send data to admin email
      console.log('Hotel Booking API call with:', hotelForm);
      // Send data to API (example call)
      fetch('/send-hotel-booking', {
        method: 'POST',
        body: JSON.stringify(hotelForm),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            successMessage: 'Your booking details have been sent to the admin successfully!',
          });
        })
        .catch((error) => {
          console.error('Error sending hotel booking:', error);
        });
    }
  };

  validateFlightForm = () => {
    const { from, to, start, return: returnDate, adults, child, infants } = this.state.flightForm;
    const errors = {};
    if (!from || !to || !start || !returnDate || adults <= 0) {
      errors.flightForm = 'All fields are required for flight booking';
    }
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  validateHotelForm = () => {
    const { destination, checkin, checkout, rooms, adults, children, name, email, phone } = this.state.hotelForm;
    const errors = {};
    if (!destination || !checkin || !checkout || !name || !email || !phone) {
      errors.hotelForm = 'All fields are required for hotel booking';
    }
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };


  render() {
    const { activeTab, flightForm, hotelForm, successMessage, errors } = this.state;
    return (
      <>
  <a className="skip-link screen-reader-text" href="#content">
    Skip to content
  </a>

  <div
    data-elementor-type="wp-page"
    data-elementor-id={107}
    className="elementor elementor-107"
    data-elementor-post-type="page"
  >
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-8a5cded elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="8a5cded"
      data-element_type="section"
      data-settings='{"background_background":"gradient"}'
    >
      <div className="elementor-background-overlay" />
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7e4b527"
          data-id="7e4b527"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-095da5f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="095da5f"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1fc58cd"
                  data-id="1fc58cd"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-3b6e185 elementor-widget__width-auto elementor-absolute elementor-hidden-tablet elementor-hidden-mobile elementor-invisible elementor-widget elementor-widget-image"
                      data-id="3b6e185"
                      data-element_type="widget"
                      data-settings='{"_position":"absolute","_animation":"fadeInLeft"}'
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <img
                          width={241}
                          height={156}
                          src={plane}
                          className="attachment-full size-full wp-image-15"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-96c6cb6 elementor-position-left elementor-vertical-align-middle elementor-mobile-position-right elementor-view-default elementor-widget elementor-widget-icon-box"
                      data-id="96c6cb6"
                      data-element_type="widget"
                      data-widget_type="icon-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-icon-box-wrapper">
                          <div className="elementor-icon-box-icon">
                            <span className="elementor-icon elementor-animation-">
                              <svg
                                aria-hidden="true"
                                className="e-font-icon-svg e-fas-globe-americas"
                                viewBox="0 0 496 512"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm82.29 357.6c-3.9 3.88-7.99 7.95-11.31 11.28-2.99 3-5.1 6.7-6.17 10.71-1.51 5.66-2.73 11.38-4.77 16.87l-17.39 46.85c-13.76 3-28 4.69-42.65 4.69v-27.38c1.69-12.62-7.64-36.26-22.63-51.25-6-6-9.37-14.14-9.37-22.63v-32.01c0-11.64-6.27-22.34-16.46-27.97-14.37-7.95-34.81-19.06-48.81-26.11-11.48-5.78-22.1-13.14-31.65-21.75l-.8-.72a114.792 114.792 0 0 1-18.06-20.74c-9.38-13.77-24.66-36.42-34.59-51.14 20.47-45.5 57.36-82.04 103.2-101.89l24.01 12.01C203.48 89.74 216 82.01 216 70.11v-11.3c7.99-1.29 16.12-2.11 24.39-2.42l28.3 28.3c6.25 6.25 6.25 16.38 0 22.63L264 112l-10.34 10.34c-3.12 3.12-3.12 8.19 0 11.31l4.69 4.69c3.12 3.12 3.12 8.19 0 11.31l-8 8a8.008 8.008 0 0 1-5.66 2.34h-8.99c-2.08 0-4.08.81-5.58 2.27l-9.92 9.65a8.008 8.008 0 0 0-1.58 9.31l15.59 31.19c2.66 5.32-1.21 11.58-7.15 11.58h-5.64c-1.93 0-3.79-.7-5.24-1.96l-9.28-8.06a16.017 16.017 0 0 0-15.55-3.1l-31.17 10.39a11.95 11.95 0 0 0-8.17 11.34c0 4.53 2.56 8.66 6.61 10.69l11.08 5.54c9.41 4.71 19.79 7.16 30.31 7.16s22.59 27.29 32 32h66.75c8.49 0 16.62 3.37 22.63 9.37l13.69 13.69a30.503 30.503 0 0 1 8.93 21.57 46.536 46.536 0 0 1-13.72 32.98zM417 274.25c-5.79-1.45-10.84-5-14.15-9.97l-17.98-26.97a23.97 23.97 0 0 1 0-26.62l19.59-29.38c2.32-3.47 5.5-6.29 9.24-8.15l12.98-6.49C440.2 193.59 448 223.87 448 256c0 8.67-.74 17.16-1.82 25.54L417 274.25z" />
                              </svg>{" "}
                            </span>
                          </div>
                          <div className="elementor-icon-box-content">
                            <h3 className="elementor-icon-box-title">
                              <span>Explore the World! </span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-6c5b724 elementor-headline--style-rotate elementor-widget elementor-widget-animated-headline"
                      data-id="6c5b724"
                      data-element_type="widget"
                      data-settings='{"headline_style":"rotate","animation_type":"drop-in","rotating_text":"Flights\nHotels\nCars","loop":"yes","rotate_iteration_delay":2500}'
                      data-widget_type="animated-headline.default"
                    >
                      <div className="elementor-widget-container">
                        <h3 className="elementor-headline elementor-headline-animation-type-drop-in">
                          <span className="elementor-headline-plain-text elementor-headline-text-wrapper">
                            Search And Book
                          </span>
                          <span className="elementor-headline-dynamic-wrapper elementor-headline-text-wrapper">
                            <span className="elementor-headline-dynamic-text elementor-headline-text-active">
                              Flights{" "}
                            </span>
                            <span className="elementor-headline-dynamic-text">
                              Hotels{" "}
                            </span>
                            <span className="elementor-headline-dynamic-text">
                              Cars{" "}
                            </span>
                          </span>
                        </h3>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-e6d2072 elementor-widget elementor-widget-text-editor"
                      data-id="e6d2072"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        Quick and easy way to get access to all available offers
                        on your desired dates.
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-39f21ae elementor-widget__width-auto elementor-absolute elementor-hidden-tablet elementor-hidden-mobile elementor-invisible elementor-widget elementor-widget-image"
                      data-id="39f21ae"
                      data-element_type="widget"
                      data-settings='{"_position":"absolute","_animation":"fadeInUp"}'
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <img
                          decoding="async"
                          width={195}
                          height={151}
                          src={img3}
                          className="attachment-full size-full wp-image-34"
                          alt=""
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a790e66"
                  data-id="a790e66"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-0859072 elementor-widget__width-auto elementor-absolute elementor-hidden-tablet elementor-hidden-mobile elementor-invisible elementor-widget elementor-widget-image"
                      
                      data-element_type="widget"
                      data-settings='{"_position":"absolute","_animation":"fadeInUp"}'
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <img
                          loading="lazy"
                          decoding="async"
                          width={133}
                          height={89}
                          src={img2}
                          className="attachment-full size-full wp-image-16"
                          alt=""
                        />{" "}
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-8da5c64 elementor-widget-mobile__width-inherit elementor-widget-tablet__width-auto elementor-widget elementor-widget-image"
                      data-id="8da5c64"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        {/*<img loading="lazy" decoding="async" width="622" height="510" src="wp-content/uploads/2024/09/banner_image.png" class="attachment-full size-full wp-image-42" alt="" srcset="https://uktravelroute.co.uk/wp-content/uploads/2024/09/banner_image.png 622w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/banner_image-300x246.png 300w" sizes="(max-width: 622px) 100vw, 622px" />		*/}
                        <div className="col-lg-4 col-md-6 banner-right">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === 'flight' ? 'active' : ''}`}
              onClick={() => this.handleTabSwitch('flight')}
              id="flight-tab"
              role="tab"
              aria-controls="flight"
              aria-selected={activeTab === 'flight'}
            >
              Flights
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === 'hotel' ? 'active' : ''}`}
              onClick={() => this.handleTabSwitch('hotel')}
              id="hotel-tab"
              role="tab"
              aria-controls="hotel"
              aria-selected={activeTab === 'hotel'}
            >
              Hotels
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          {/* Flight Tab Content */}
          <div
            className={`tab-pane fade ${activeTab === 'flight' ? 'show active' : ''}`}
            id="flight"
            role="tabpanel"
            aria-labelledby="flight-tab"
          >
            <form className="form-wrap">
              <input
                type="text"
                className="form-control"
                name="from"
                value={flightForm.from}
                onChange={(e) => this.handleInputChange('flightForm', e)}
                placeholder="From"
                required
              />
              <input
                type="text"
                className="form-control marginRightZero"
                name="to"
                value={flightForm.to}
                onChange={(e) => this.handleInputChange('flightForm', e)}
                placeholder="To"
                required
              />
              <DatePicker
                selected={flightForm.start}
                onChange={(date) => this.handleDateChange('flightForm', date, 'start')}
                placeholderText="Start Date"
                required
              />
              <DatePicker
              className='marginRightZero'
                selected={flightForm.return}
                onChange={(date) => this.handleDateChange('flightForm', date, 'return')}
                placeholderText="Return Date"
                required
              />
              <input
              className="form-control"
                type="number"
                min={1}
                max={20}
                name="adults"
                value={flightForm.adults}
                onChange={(e) => this.handleInputChange('flightForm', e)}
                placeholder="Adults"
                required
              />
              <input
              className="form-control marginRightZero"
                type="number"
                min={0}
                max={20}
                name="child"
                value={flightForm.child}
                onChange={(e) => this.handleInputChange('flightForm', e)}
                placeholder="Children"
                required
              />
              <input
              className="form-control"
                type="number"
                min={0}
                max={20}
                name="infants"
                value={flightForm.infants}
                onChange={(e) => this.handleInputChange('flightForm', e)}
                placeholder="Infants"
                required
              />
              <select
                className="form-control"
                name="class"
                value={flightForm.class}
                onChange={(e) => this.handleInputChange('flightForm', e)}
              >
                <option>Economy</option>
                <option>Premium</option>
                <option>Business</option>
              </select>
              <button
                type="button"
                className="primary-btn text-uppercase"
                onClick={this.handleSubmitFlight}
              >
                Search Flights
              </button>
            </form>
          </div>

          {/* Hotel Tab Content */}
          <div
            className={`tab-pane fade ${activeTab === 'hotel' ? 'show active' : ''}`}
            id="hotel"
            role="tabpanel"
            aria-labelledby="hotel-tab"
          >
            <form className="form-wrap">
              <Autocomplete
              className="form-control destination"
                value={hotelForm.destination}
                items={['Paris', 'London', 'New York']} // Example destinations
                onChange={(e) => this.handleInputChange('hotelForm', e)}
                renderItem={(item) => <div>{item}</div>}
                wrapperStyle={{ position: 'relative' }}
                inputProps={{
                  name: 'destination',
                  placeholder: 'Hotel Destination',
                  required: true,
                  className:"form-control destination"
                }}
              />
              <DatePicker
              
                selected={hotelForm.checkin}
                onChange={(date) => this.handleDateChange('hotelForm', date, 'checkin')}
                placeholderText="Check-in Date"
                required
              />
              <DatePicker
              
                selected={hotelForm.checkout}
                onChange={(date) => this.handleDateChange('hotelForm', date, 'checkout')}
                placeholderText="Check-out Date"
                required
              />
              <input
              className="form-control"
                type="number"
                name="rooms"
                value={hotelForm.rooms}
                onChange={(e) => this.handleInputChange('hotelForm', e)}
                placeholder="Rooms"
                required
              />
              <input
              className="form-control"
                type="number"
                name="adults"
                value={hotelForm.adults}
                onChange={(e) => this.handleInputChange('hotelForm', e)}
                placeholder="Adults"
                required
              />
              <input
              className="form-control"
                type="number"
                name="children"
                value={hotelForm.children}
                onChange={(e) => this.handleInputChange('hotelForm', e)}
                placeholder="Children"
                required
              />
              <input
              className="form-control"
                type="text"
                name="name"
                value={hotelForm.name}
                onChange={(e) => this.handleInputChange('hotelForm', e)}
                placeholder="Name"
                required
              />
              <input
              className="form-control clearLeft"
                type="email"
                name="email"
                value={hotelForm.email}
                onChange={(e) => this.handleInputChange('hotelForm', e)}
                placeholder="Email"
                required
              />
              <input
              className="form-control"
                type="text"
                name="phone"
                value={hotelForm.phone}
                onChange={(e) => this.handleInputChange('hotelForm', e)}
                placeholder="Phone"
                required
              />
              <button
                type="button"
                className="primary-btn text-uppercase"
                onClick={this.handleSubmitHotel}
              >
                Book Hotel
              </button>
            </form>
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
          </div>
        </div>
      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-aa58f4d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="aa58f4d"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-285b781"
                  data-id="285b781"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap"></div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-9ad998a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="9ad998a"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-no">
                <div
                  className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-2d8aa13"
                  data-id="2d8aa13"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-1b9a9b5 elementor-widget elementor-widget-heading"
                      data-id="1b9a9b5"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h3 className="elementor-heading-title elementor-size-default">
                          Secure &amp; Cheap Prices
                        </h3>{" "}
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-7ca5669 elementor-widget elementor-widget-heading"
                      data-id="7ca5669"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          Why Travel With Us
                        </h2>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-43129fb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="43129fb"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-no">
                <div
                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-d94d17c"
                  data-id="d94d17c"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-9766f41 elementor-position-top elementor-widget elementor-widget-image-box"
                      data-id="9766f41"
                      data-element_type="widget"
                      data-widget_type="image-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-image-box-wrapper">
                          <figure className="elementor-image-box-img">
                            <a href="#" tabIndex={-1}>
                              <img
                                loading="lazy"
                                decoding="async"
                                width={512}
                                height={512}
                                src={img15}
                                className="elementor-animation-float attachment-full size-full wp-image-148"
                                alt=""
                                //srcSet="https://uktravelroute.co.uk/wp-content/uploads/2024/09/destination.png 512w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/destination-300x300.png 300w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/destination-150x150.png 150w"
                                sizes="(max-width: 512px) 100vw, 512px"
                              />
                            </a>
                          </figure>
                          <div className="elementor-image-box-content">
                            <h3 className="elementor-image-box-title">
                              <a href="#">700+ Destinations</a>
                            </h3>
                            <p className="elementor-image-box-description">
                              Find amazing deals to worldwide destinations. We
                              always offer the best value for our customers.
                            </p>
                          </div>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-095a268"
                  data-id="095a268"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-aacfa86 elementor-position-top elementor-widget elementor-widget-image-box"
                      data-id="aacfa86"
                      data-element_type="widget"
                      data-widget_type="image-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-image-box-wrapper">
                          <figure className="elementor-image-box-img">
                            <img
                              loading="lazy"
                              decoding="async"
                              width={512}
                              height={512}
                              src={img20}
                              className="elementor-animation-float attachment-full size-full wp-image-150"
                              alt=""
                              //srcSet="https://uktravelroute.co.uk/wp-content/uploads/2024/09/wallet-passes-app.png 512w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/wallet-passes-app-300x300.png 300w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/wallet-passes-app-150x150.png 150w"
                              sizes="(max-width: 512px) 100vw, 512px"
                            />
                          </figure>
                          <div className="elementor-image-box-content">
                            <h3 className="elementor-image-box-title">
                              Low Rates
                            </h3>
                            <p className="elementor-image-box-description">
                              We offer the best lowest price with full guarantee
                              of marking no other comparison.
                            </p>
                          </div>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8a1b7c5"
                  data-id="8a1b7c5"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-c58621c elementor-position-top elementor-widget elementor-widget-image-box"
                      data-id="c58621c"
                      data-element_type="widget"
                      data-widget_type="image-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-image-box-wrapper">
                          <figure className="elementor-image-box-img">
                            <img
                              loading="lazy"
                              decoding="async"
                              width={512}
                              height={512}
                              src={img18}
                              className="elementor-animation-float attachment-full size-full wp-image-154"
                              alt=""
                              //srcSet="https://uktravelroute.co.uk/wp-content/uploads/2024/09/star-rating.png 512w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/star-rating-300x300.png 300w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/star-rating-150x150.png 150w"
                              sizes="(max-width: 512px) 100vw, 512px"
                            />
                          </figure>
                          <div className="elementor-image-box-content">
                            <h3 className="elementor-image-box-title">
                              Reviews
                            </h3>
                            <p className="elementor-image-box-description">
                              We are privileged enough to maintain a big
                              reputation having thousands of satisfied clients.
                            </p>
                          </div>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-bac71f5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="bac71f5"
      data-element_type="section"
      data-settings='{"background_background":"classic"}'
    >
      <div className="elementor-background-overlay" />
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-93316f6"
          data-id="93316f6"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-f97ba5d elementor-widget elementor-widget-heading"
              data-id="f97ba5d"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h3 className="elementor-heading-title elementor-size-default">
                  Let's fly away
                </h3>{" "}
              </div>
            </div>
            <div
              className="elementor-element elementor-element-84d937f elementor-widget elementor-widget-heading"
              data-id="84d937f"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  Cheap Flight Deals
                </h2>{" "}
              </div>
            </div>
            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-4b4ed06 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="4b4ed06"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-b9a8bce"
                  data-id="b9a8bce"
                  data-element_type="column"
                  data-settings='{"background_background":"classic"}'
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div className="elementor-background-overlay" />
                    <div
                      className="elementor-element elementor-element-409a356 elementor-view-stacked elementor-position-left elementor-vertical-align-middle elementor-tablet-position-left elementor-mobile-position-left elementor-shape-circle elementor-widget elementor-widget-icon-box"
                      data-id="409a356"
                      data-element_type="widget"
                      data-widget_type="icon-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-icon-box-wrapper">
                          <div className="elementor-icon-box-icon">
                            <a
                              href="#"
                              className="elementor-icon elementor-animation-"
                              tabIndex={-1}
                            >
                              <svg
                                aria-hidden="true"
                                className="e-font-icon-svg e-fas-map-marker-alt"
                                viewBox="0 0 384 512"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" />
                              </svg>{" "}
                            </a>
                          </div>
                          <div className="elementor-icon-box-content">
                            <h6 className="elementor-icon-box-title">
                              <a href="#">Johannesburg, Africa </a>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-1bed2fc elementor-widget elementor-widget-heading"
                      data-id="1bed2fc"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          Africa – Amazing African Safari
                        </h2>{" "}
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-93f4af9 elementor-widget elementor-widget-image"
                      data-id="93f4af9"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <img
                          loading="lazy"
                          decoding="async"
                          width={310}
                          height={260}
                          src={img4}
                          className="attachment-full size-full wp-image-85"
                          alt=""
                          //srcSet="https://uktravelroute.co.uk/wp-content/uploads/2024/09/Photographer_sec3.jpg 310w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/Photographer_sec3-300x252.jpg 300w"
                          sizes="(max-width: 310px) 100vw, 310px"
                        />{" "}
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-cf0b9b4 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                      data-id="cf0b9b4"
                      data-element_type="widget"
                      data-widget_type="divider.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-divider">
                          <span className="elementor-divider-separator"></span>
                        </div>
                      </div>
                    </div>
                    <section
                      className="elementor-section elementor-inner-section elementor-element elementor-element-6d6edcb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="6d6edcb"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-no">
                        <div
                          className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-331ce52"
                          data-id="331ce52"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-8d8881a elementor-widget__width-auto elementor-widget elementor-widget-heading"
                              data-id="8d8881a"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h3 className="elementor-heading-title elementor-size-default">
                                  $545
                                </h3>{" "}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-a3f5743 elementor-widget__width-auto elementor-widget elementor-widget-text-editor"
                              data-id="a3f5743"
                              data-element_type="widget"
                              data-widget_type="text-editor.default"
                            >
                              <div className="elementor-widget-container">
                                /Person{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-216e761"
                          data-id="216e761"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-d1d60ea elementor-align-center elementor-widget elementor-widget-button"
                              data-id="d1d60ea"
                              data-element_type="widget"
                              data-widget_type="button.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-button-wrapper">
                                  <a
                                    className="elementor-button elementor-button-link elementor-size-sm elementor-animation-float"
                                    href="#"
                                  >
                                    <span className="elementor-button-content-wrapper">
                                      <span className="elementor-button-text">
                                        View Availability
                                      </span>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c2f26c6"
                  data-id="c2f26c6"
                  data-element_type="column"
                  data-settings='{"background_background":"classic"}'
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div className="elementor-background-overlay" />
                    <div
                      className="elementor-element elementor-element-cc2f5fb elementor-view-stacked elementor-position-left elementor-vertical-align-middle elementor-tablet-position-left elementor-mobile-position-left elementor-shape-circle elementor-widget elementor-widget-icon-box"
                      data-id="cc2f5fb"
                      data-element_type="widget"
                      data-widget_type="icon-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-icon-box-wrapper">
                          <div className="elementor-icon-box-icon">
                            <a
                              href="#"
                              className="elementor-icon elementor-animation-"
                              tabIndex={-1}
                            >
                              <svg
                                aria-hidden="true"
                                className="e-font-icon-svg e-fas-map-marker-alt"
                                viewBox="0 0 384 512"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" />
                              </svg>{" "}
                            </a>
                          </div>
                          <div className="elementor-icon-box-content">
                            <h6 className="elementor-icon-box-title">
                              <a href="#">Hong Kong, China </a>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-61b7a6d elementor-widget elementor-widget-heading"
                      data-id="61b7a6d"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          China – Suzhou and Zhouzhuang
                        </h2>{" "}
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-c68d10d elementor-widget elementor-widget-image"
                      data-id="c68d10d"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <img
                          loading="lazy"
                          decoding="async"
                          width={310}
                          height={260}
                          src={img1}
                          className="attachment-full size-full wp-image-87"
                          alt=""
                          //srcSet="https://uktravelroute.co.uk/wp-content/uploads/2024/09/Building_sec3.jpg 310w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/Building_sec3-300x252.jpg 300w"
                          sizes="(max-width: 310px) 100vw, 310px"
                        />{" "}
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-275a9f0 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                      data-id="275a9f0"
                      data-element_type="widget"
                      data-widget_type="divider.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-divider">
                          <span className="elementor-divider-separator"></span>
                        </div>
                      </div>
                    </div>
                    <section
                      className="elementor-section elementor-inner-section elementor-element elementor-element-5e25983 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="5e25983"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-no">
                        <div
                          className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-111b16d"
                          data-id="111b16d"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-933d9fb elementor-widget__width-auto elementor-widget elementor-widget-heading"
                              data-id="933d9fb"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h3 className="elementor-heading-title elementor-size-default">
                                  $545
                                </h3>{" "}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-7ed077f elementor-widget__width-auto elementor-widget elementor-widget-text-editor"
                              data-id="7ed077f"
                              data-element_type="widget"
                              data-widget_type="text-editor.default"
                            >
                              <div className="elementor-widget-container">
                                /Person{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-67d9331"
                          data-id="67d9331"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-07d6603 elementor-align-center elementor-widget elementor-widget-button"
                              data-id="07d6603"
                              data-element_type="widget"
                              data-widget_type="button.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-button-wrapper">
                                  <a
                                    className="elementor-button elementor-button-link elementor-size-sm elementor-animation-float"
                                    href="#"
                                  >
                                    <span className="elementor-button-content-wrapper">
                                      <span className="elementor-button-text">
                                        View Availability
                                      </span>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-feb5213"
                  data-id="feb5213"
                  data-element_type="column"
                  data-settings='{"background_background":"classic"}'
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div className="elementor-background-overlay" />
                    <div
                      className="elementor-element elementor-element-dd24504 elementor-view-stacked elementor-position-left elementor-vertical-align-middle elementor-tablet-position-left elementor-mobile-position-left elementor-shape-circle elementor-widget elementor-widget-icon-box"
                      data-id="dd24504"
                      data-element_type="widget"
                      data-widget_type="icon-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-icon-box-wrapper">
                          <div className="elementor-icon-box-icon">
                            <a
                              href="#"
                              className="elementor-icon elementor-animation-"
                              tabIndex={-1}
                            >
                              <svg
                                aria-hidden="true"
                                className="e-font-icon-svg e-fas-map-marker-alt"
                                viewBox="0 0 384 512"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" />
                              </svg>{" "}
                            </a>
                          </div>
                          <div className="elementor-icon-box-content">
                            <h6 className="elementor-icon-box-title">
                              <a href="#">Buenos Aires, Argentina </a>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-c831e7d elementor-widget elementor-widget-heading"
                      data-id="c831e7d"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          Argentina – Great Diving Trip
                        </h2>{" "}
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-55d2228 elementor-widget elementor-widget-image"
                      data-id="55d2228"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <img
                          loading="lazy"
                          decoding="async"
                          width={310}
                          height={260}
                          src={img11}
                          className="attachment-full size-full wp-image-90"
                          alt=""
                          //srcSet="https://uktravelroute.co.uk/wp-content/uploads/2024/09/Swimmers_sec2.jpg 310w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/Swimmers_sec2-300x252.jpg 300w"
                          sizes="(max-width: 310px) 100vw, 310px"
                        />{" "}
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-8c65830 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                      data-id="8c65830"
                      data-element_type="widget"
                      data-widget_type="divider.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-divider">
                          <span className="elementor-divider-separator"></span>
                        </div>
                      </div>
                    </div>
                    <section
                      className="elementor-section elementor-inner-section elementor-element elementor-element-9f2a9d6 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="9f2a9d6"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-no">
                        <div
                          className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-114e580"
                          data-id="114e580"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-3bdd496 elementor-widget__width-auto elementor-widget elementor-widget-heading"
                              data-id="3bdd496"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h3 className="elementor-heading-title elementor-size-default">
                                  $545
                                </h3>{" "}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-c164a3d elementor-widget__width-auto elementor-widget elementor-widget-text-editor"
                              data-id="c164a3d"
                              data-element_type="widget"
                              data-widget_type="text-editor.default"
                            >
                              <div className="elementor-widget-container">
                                /Person{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-4a879b6"
                          data-id="4a879b6"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-a1ed705 elementor-align-center elementor-widget elementor-widget-button"
                              data-id="a1ed705"
                              data-element_type="widget"
                              data-widget_type="button.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-button-wrapper">
                                  <a
                                    className="elementor-button elementor-button-link elementor-size-sm elementor-animation-float"
                                    href="#"
                                  >
                                    <span className="elementor-button-content-wrapper">
                                      <span className="elementor-button-text">
                                        View Availability
                                      </span>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-87d89bd elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="87d89bd"
      data-element_type="section"
      data-settings='{"background_background":"gradient"}'
    >
      <div className="elementor-background-overlay" />
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-9a59b61"
          data-id="9a59b61"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-bdbed02 elementor-widget elementor-widget-image"
              data-id="bdbed02"
              data-element_type="widget"
              data-widget_type="image.default"
            >
              <div className="elementor-widget-container">
                <img
                  loading="lazy"
                  decoding="async"
                  width={1920}
                  height={1080}
                  src={img19}
                  className="elementor-animation-float attachment-full size-full wp-image-139"
                  alt=""
                 // srcSet="https://uktravelroute.co.uk/wp-content/uploads/2024/09/the_savoy_hotel_luxury_wedding_flowers_larry_walshe_studios.jpg 1920w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/the_savoy_hotel_luxury_wedding_flowers_larry_walshe_studios-300x169.jpg 300w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/the_savoy_hotel_luxury_wedding_flowers_larry_walshe_studios-1024x576.jpg 1024w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/the_savoy_hotel_luxury_wedding_flowers_larry_walshe_studios-768x432.jpg 768w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/the_savoy_hotel_luxury_wedding_flowers_larry_walshe_studios-1536x864.jpg 1536w"
                  sizes="(max-width: 1920px) 100vw, 1920px"
                />{" "}
              </div>
            </div>
            <div
              className="elementor-element elementor-element-8a1e635 elementor-widget__width-auto elementor-absolute elementor-widget elementor-widget-image"
              data-id="8a1e635"
              data-element_type="widget"
              data-settings='{"_position":"absolute"}'
              data-widget_type="image.default"
            >
              <div className="elementor-widget-container">
                <img
                  loading="lazy"
                  decoding="async"
                  width={1600}
                  height={900}
                  src={img17}
                  className="elementor-animation-float attachment-full size-full wp-image-144"
                  alt=""
                  //srcSet="https://uktravelroute.co.uk/wp-content/uploads/2024/09/skynews-heathrow-british-airways_6500614.jpg 1600w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/skynews-heathrow-british-airways_6500614-300x169.jpg 300w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/skynews-heathrow-british-airways_6500614-1024x576.jpg 1024w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/skynews-heathrow-british-airways_6500614-768x432.jpg 768w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/skynews-heathrow-british-airways_6500614-1536x864.jpg 1536w"
                  sizes="(max-width: 1600px) 100vw, 1600px"
                />{" "}
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3182c0c"
          data-id="3182c0c"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-322181b elementor-widget elementor-widget-heading"
              data-id="322181b"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h3 className="elementor-heading-title elementor-size-default">
                  About Us
                </h3>{" "}
              </div>
            </div>
            <div
              className="elementor-element elementor-element-6af5a4d elementor-widget elementor-widget-heading"
              data-id="6af5a4d"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  World Best Travel Agency Company
                </h2>{" "}
              </div>
            </div>
            <div
              className="elementor-element elementor-element-19b8ce8 elementor-hidden-tablet elementor-widget elementor-widget-text-editor"
              data-id="19b8ce8"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container">
                <p>
                  UK Travel Route is your one-stop online travel agency offering
                  international and domestic flights from all major airports of
                  UK on all major airlines, vehicle hire options from top car
                  rental companies and hotel reservations book able for
                  properties on worldwide destinations. Contact our travel
                  experts and let us start planning for your travel within your
                  budget.
                </p>
                <p>
                  You can contact us over the telephone or e-mail. We are in
                  partnership with some leading brands in UK comprising major
                  principal suppliers and global distribution systems providers.
                  This enables us to provide to our clients to select from
                  number of cheap travel options.
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-1dd8048 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="1dd8048"
      data-element_type="section"
    >
      <div className="elementor-background-overlay" />
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4b93d44"
          data-id="4b93d44"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-2a52f2f elementor-widget__width-auto elementor-absolute elementor-hidden-tablet elementor-hidden-mobile elementor-widget elementor-widget-image"
              data-id="2a52f2f"
              data-element_type="widget"
              data-settings='{"_position":"absolute"}'
              data-widget_type="image.default"
            >
              <div className="elementor-widget-container">
                <img
                  loading="lazy"
                  decoding="async"
                  width={502}
                  height={204}
                  src={img7}
                  className="attachment-full size-full wp-image-46"
                  alt=""
                  //srcSet="https://uktravelroute.co.uk/wp-content/uploads/2024/09/Sec2_vector_img.png 502w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/Sec2_vector_img-300x122.png 300w"
                  sizes="(max-width: 502px) 100vw, 502px"
                />{" "}
              </div>
            </div>
            <div
              className="elementor-element elementor-element-048826c elementor-widget elementor-widget-heading"
              data-id="048826c"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h3 className="elementor-heading-title elementor-size-default">
                  NEWS &amp; ARTICLES
                </h3>{" "}
              </div>
            </div>
            <div
              className="elementor-element elementor-element-fa0c29a elementor-widget elementor-widget-heading"
              data-id="fa0c29a"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  Stay Update with Traveltrek
                </h2>{" "}
              </div>
            </div>
            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-aacba8a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="aacba8a"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-54cbbc0"
                  data-id="54cbbc0"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-affffca elementor-widget elementor-widget-image"
                      data-id="affffca"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <img
                          loading="lazy"
                          decoding="async"
                          width={350}
                          height={350}
                          src={img8}
                          className="elementor-animation-bob attachment-full size-full wp-image-53"
                          alt=""
                          //srcSet="https://uktravelroute.co.uk/wp-content/uploads/2024/09/Sec7_img1.jpg 350w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/Sec7_img1-300x300.jpg 300w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/Sec7_img1-150x150.jpg 150w"
                          sizes="(max-width: 350px) 100vw, 350px"
                        />{" "}
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-4645267 elementor-widget__width-auto elementor-widget elementor-widget-icon-box"
                      data-id={4645267}
                      data-element_type="widget"
                      data-widget_type="icon-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-icon-box-wrapper">
                          <div className="elementor-icon-box-content">
                            <h3 className="elementor-icon-box-title">
                              <span>June 6, 2016 • John Smith </span>
                            </h3>
                            <p className="elementor-icon-box-description">
                              Change your place and get the fresh air{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-bb1bb26"
                  data-id="bb1bb26"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-33f7c39 elementor-widget elementor-widget-image"
                      data-id="33f7c39"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <img
                          loading="lazy"
                          decoding="async"
                          width={350}
                          height={350}
                          src={img9}
                          className="elementor-animation-bob attachment-full size-full wp-image-58"
                          alt=""
                          //srcSet="https://uktravelroute.co.uk/wp-content/uploads/2024/09/Sec7_img2.jpg 350w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/Sec7_img2-300x300.jpg 300w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/Sec7_img2-150x150.jpg 150w"
                          sizes="(max-width: 350px) 100vw, 350px"
                        />{" "}
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-fff12ce elementor-widget__width-auto elementor-widget elementor-widget-icon-box"
                      data-id="fff12ce"
                      data-element_type="widget"
                      data-widget_type="icon-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-icon-box-wrapper">
                          <div className="elementor-icon-box-content">
                            <h3 className="elementor-icon-box-title">
                              <span>June 6, 2016 • John Smith </span>
                            </h3>
                            <p className="elementor-icon-box-description">
                              Change your place and get the fresh air{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-684e0f4"
                  data-id="684e0f4"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-86982e2 elementor-widget elementor-widget-image"
                      data-id="86982e2"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <img
                          loading="lazy"
                          decoding="async"
                          width={350}
                          height={350}
                          src={img10}
                          className="elementor-animation-bob attachment-full size-full wp-image-67"
                          alt=""
                          //srcSet="https://uktravelroute.co.uk/wp-content/uploads/2024/09/Sec7_img3.jpg 350w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/Sec7_img3-300x300.jpg 300w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/Sec7_img3-150x150.jpg 150w"
                          sizes="(max-width: 350px) 100vw, 350px"
                        />{" "}
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-35895bb elementor-widget__width-auto elementor-widget elementor-widget-icon-box"
                      data-id="35895bb"
                      data-element_type="widget"
                      data-widget_type="icon-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-icon-box-wrapper">
                          <div className="elementor-icon-box-content">
                            <h3 className="elementor-icon-box-title">
                              <span>June 6, 2016 • John Smith </span>
                            </h3>
                            <p className="elementor-icon-box-description">
                              Change your place and get the fresh air{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>

</>

    );
  }
}

export default Home;
