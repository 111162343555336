import React, { Component } from 'react';
import '../css/About.css';
import img17 from '../images/skynews-heathrow-british-airways_6500614.jpg';
import img18 from '../images/star-rating.png';
import img19 from '../images/the_savoy_hotel_luxury_wedding_flowers_larry_walshe_studios.jpg';

class About extends Component {
  render() {
    return(
    <>
    <div
  data-elementor-type="wp-page"
  data-elementor-id={230}
  className="elementor elementor-230"
  data-elementor-post-type="page"
>
  <section
    className="elementor-section elementor-top-section elementor-element elementor-element-b042a32 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
    data-id="b042a32"
    data-element_type="section"
  >
    <div className="elementor-background-overlay" />
    <div className="elementor-container elementor-column-gap-default">
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-dd094e5"
        data-id="dd094e5"
        data-element_type="column"
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-05863ad elementor-widget elementor-widget-heading"
            data-id="05863ad"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h3 className="elementor-heading-title elementor-size-default">
                About Us
              </h3>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-9cb3a88 elementor-widget elementor-widget-heading"
            data-id="9cb3a88"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                World Best Travel Agency Company
              </h2>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-dc01d88 elementor-hidden-tablet elementor-widget elementor-widget-text-editor"
            data-id="dc01d88"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                UK Travel Route is your one-stop online travel agency offering
                international and domestic flights from all major airports of UK
                on all major airlines, vehicle hire options from top car rental
                companies and hotel reservations book able for properties on
                worldwide destinations. Contact our travel experts and let us
                start planning for your travel within your budget.
              </p>
              <p>
                You can contact us over the telephone or e-mail. We are in
                partnership with some leading brands in UK comprising major
                principal suppliers and global distribution systems providers.
                This enables us to provide to our clients to select from number
                of cheap travel options.
              </p>{" "}
            </div>
          </div>
        </div>
      </div>
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-e59cae7"
        data-id="e59cae7"
        data-element_type="column"
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-4047b02 elementor-widget elementor-widget-image"
            data-id="4047b02"
            data-element_type="widget"
            data-widget_type="image.default"
          >
            <div className="elementor-widget-container">
              <img
                decoding="async"
                width={1920}
                height={1080}
                src={img19}
                className="elementor-animation-float attachment-full size-full wp-image-139"
                alt=""
                //srcSet="https://uktravelroute.co.uk/wp-content/uploads/2024/09/the_savoy_hotel_luxury_wedding_flowers_larry_walshe_studios.jpg 1920w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/the_savoy_hotel_luxury_wedding_flowers_larry_walshe_studios-300x169.jpg 300w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/the_savoy_hotel_luxury_wedding_flowers_larry_walshe_studios-1024x576.jpg 1024w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/the_savoy_hotel_luxury_wedding_flowers_larry_walshe_studios-768x432.jpg 768w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/the_savoy_hotel_luxury_wedding_flowers_larry_walshe_studios-1536x864.jpg 1536w"
                sizes="(max-width: 1920px) 100vw, 1920px"
              />{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-b14db82 elementor-widget__width-auto elementor-absolute elementor-widget elementor-widget-image"
            data-id="b14db82"
            data-element_type="widget"
            data-settings='{"_position":"absolute"}'
            data-widget_type="image.default"
          >
            <div className="elementor-widget-container">
              <img
                decoding="async"
                width={1600}
                height={900}
                src={img17}
                className="elementor-animation-float attachment-full size-full wp-image-144"
                alt=""
                //srcSet="https://uktravelroute.co.uk/wp-content/uploads/2024/09/skynews-heathrow-british-airways_6500614.jpg 1600w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/skynews-heathrow-british-airways_6500614-300x169.jpg 300w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/skynews-heathrow-british-airways_6500614-1024x576.jpg 1024w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/skynews-heathrow-british-airways_6500614-768x432.jpg 768w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/skynews-heathrow-british-airways_6500614-1536x864.jpg 1536w"
                sizes="(max-width: 1600px) 100vw, 1600px"
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

    </>
    )
  }
}

export default About;
