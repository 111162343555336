import React, { Component } from 'react';

class PrivacyPolicy extends Component {
  render() {
    return (
      <>
      <div
  data-elementor-type="wp-page"
  data-elementor-id={3}
  className="elementor elementor-3"
  data-elementor-post-type="page"
>
  <section
    className="elementor-section elementor-top-section elementor-element elementor-element-6ea4eb1 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
    data-id="6ea4eb1"
    data-element_type="section"
  >
    <div className="elementor-background-overlay" />
    <div className="elementor-container elementor-column-gap-default">
      <div
        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-aa1a683"
        data-id="aa1a683"
        data-element_type="column"
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-947d52c elementor-widget elementor-widget-heading"
            data-id="947d52c"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Privacy Policy
              </h2>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-42e2691 elementor-hidden-tablet elementor-widget elementor-widget-text-editor"
            data-id="42e2691"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                This privacy policy notice is for this website which is
                administered by UK Travel Route&nbsp;and governs the privacy of
                those who use it. The purpose of this policy is to explain to
                you how we control, process, handle and protect your personal
                information while browsing or using this website, including your
                rights under current laws and regulations. If you do not agree
                to the following policy you may wish to cease viewing / using
                this website
              </p>
              <h3>Policy key definitions:</h3>
              <p>
                1. “I”, “our”, “us”, or “we” refer to the business, UK Travel
                Route or any of its subsidiary trading names
              </p>
              <p>
                2. you”, “”your”, the user” refer to the person(s) using this
                website.
              </p>
              <p>3. GDPR means General Data Protection Act.</p>
              <p>
                4. PECR means Privacy &amp; Electronic Communications
                Regulation.
              </p>
              <p>5. ICO means Information Commissioner’s Office.</p>
              <p>
                6. Cookies mean small files stored on a user’s computer or
                device
              </p>
              <h3>Processing of your personal data</h3>
              <p>
                Under the GDPR (General Data Protection Regulation) we control
                and / or process any personal information about you
                electronically using the following lawful bases. We are
                registered with the ICO under the Data Protection Register.
              </p>
              <p>
                If, as determined by us, the lawful basis upon which we process
                your personal information changes, we will notify you about the
                change and any new lawful basis to be used if required. We shall
                stop processing your personal information if the lawful basis
                used is no longer relevant.
              </p>
              <h3>Your individual rights</h3>
              <p>
                Under the GDPR your rights are as follows. You can read more
                about your rights in details here;
              </p>
              <p>1. the right to be informed;</p>
              <p>2. the right of access;</p>
              <p>3. the right to rectification;</p>
              <p>4. the right to erasure;</p>
              <p>5. the right to restrict processing;</p>
              <p>6. the right to data portability;</p>
              <p>7. the right to object; and</p>
              <p>
                8. the right not to be subject to automated decision-making
                including profiling.
              </p>
              <p>
                You also have the right to complain to the ICO [www.ico.org.uk]
                if you feel there is a problem with the way we are handling your
                data.
              </p>
              <p>
                We handle subject access requests in accordance with the GDPR.
              </p>
              <h3>Personal Information That We Collect</h3>
              <p>
                Personal information is information that relates to an
                identifiable natural person. An identifiable natural person is
                someone that can be identified both directly and indirectly by
                their name, location data, identification number or by factors
                specific to the social, cultural, physiological, genetic, mental
                or economic identity of the natural person. Personal Information
                does not include any information that has been anonymised so
                that it can no longer be used to identify a natural person.
              </p>
              <p>
                When you use our travel services, we do collect some personal
                information from you and any devices you use. Personal
                identifying information can include your name, telephone
                numbers, address, email addresses and financial information such
                as card details. Provision of any other personal information is
                voluntary on your account, but it may become necessary in order
                to use some of our services.
              </p>
              <p>
                We automatically collect information about your interaction with
                our website and communications with us. This is the information
                that we receive via devices you use when you access our website.
                This information may include a Device ID or unique identifier, a
                unique device token, a device type or an ID for advertising. We
                may also receive location information from your mobile device,
                but most mobile devices now allow you to disable the use of your
                location settings on internet browsers. We also collect computer
                and connection information such as your page view statistics,
                referral URL, browsing history, IP address and web log
                information.
              </p>
              <p>
                We use cookies, unique identifiers, web beacons and other
                similar technologies to collect personal information about pages
                that you view, links that you clink on and other actions you may
                take whilst using our website and services.
              </p>
              <h3>Personal Information We Collect From Other Sources</h3>
              <p>
                We add on the personal information we collect with other
                information collected from third parties, and implement it to
                your account information. For example, we collect and use
                demographic information that is available in credit check
                information, additional contact information, information from
                credit bureaus and an applicable jurisdiction, which is allowed
                by some applicable national laws.
              </p>
              <h3>Social Media</h3>
              <p>
                We allow you to share pages and information from our website on
                social media sites, and these social media sites may give us
                automatic access to personal information they have retained from
                you, for example, content liked by you or viewed by you, and
                information about advertisements that you may have been shown or
                clicked on.
              </p>
              <p>
                If you allow us access to websites with video content, then you
                agree that we can share your video viewing information from
                social media sites for at least 2 years, unless you withdraw any
                connections or authorisation of the social media sites.
              </p>
              <p>
                You are in control of the personal information that you allow us
                to have access to on the privacy settings included on social
                media sites, and also by the permission you give us when you
                allow us access to this personal information that is retained by
                the social media site about you.
              </p>
              <p>
                By associating your account with an account managed by social
                media, and by authorising us access to this personal
                information, you agree that we can collect, use and keep the
                information we receive from these social media sites in
                accordance with this privacy policy. We may use other
                technologies from social media sites, as well as plug ins. For
                instance if you click on a link via a plug in on social media,
                you will be establishing a connection with that social media
                site.
              </p>
              <p>
                If you provide us with any personal information about someone
                else, you can only do so with that person’s authorisation. You
                should inform the individual about how we collect, use, keep and
                disclose their personal information according to this privacy
                policy.
              </p>
              <h3>How We Use the Personal Information We Collect From You</h3>
              <p>
                We use your personal information for many business purposes and
                for different legal reasons. Below is a summary of how we may
                use your information and for which legal bases.
              </p>
              <p>
                1. To provide you with our travel services and enable servicing
                of the travel booking
              </p>
              <p>
                2. To fulfil a binding contract with you or to comply with our
                legal obligations
              </p>
              <p>3. To provide account management and payment processing</p>
              <p>4. To improve, operate and measure our services</p>
              <p>5. To keep our services safe and secure</p>
              <p>6. To customise any site content</p>
              <p>7. To contact you regarding your account</p>
              <p>8. To resolve any disputes</p>
              <p>
                9. To troubleshoot any problems associated with your account
              </p>
              <p>10. To collect any dues</p>
              <h3>
                If we contact you for any purposes outlined above, it will be
                via telephone, email, SMS or post.
              </h3>
              <p>
                . We will use location information to provide location based
                services (i.e. search results and advertising)
              </p>
              <p>
                . We may also use your personal information to detect, prevent,
                alleviate and investigate security breaches, fraud and other
                illegal or prohibited activities
              </p>
              <p>
                . Personal information is used to pursue some of our legitimate
                interests (your rights and freedom does not outweigh these
                interests). We have controls in place to balance your rights
                with our interests.
              </p>
              <h3>Some of our legitimate interests include:</h3>
              <p>
                . Improving our services, for example reviewing any information
                which is associated with crashed or stalled site pages
                experienced by users, allowing us to fix any issues and ensure
                you have a better online experience with us.
              </p>
              <p>
                . Personalising and improving our advertising methods based on
                our preferences on advertising.
              </p>
              <p>
                . Contacting you with discount offers and special promotions or
                surveys as authorised by law.
              </p>
              <p>
                . Contacting you about any current or public policy events which
                are related to your ability to use our website and services
                (this may include letter writing or other public campaigns
                related to public policy).
              </p>
              <p>
                . Delivering specifically targeted marketing, promotional deals
                and service updates that are based on your communication
                preferences.
              </p>
              <p>
                . Analysing our open and click rates on email marketing
                campaigns such as newsletters.
              </p>
              <p>
                . Monitoring and improving the data and information security of
                our website
              </p>
              <p>
                With your consent, we may also use your personal information to
                provide you with marketing via emails, SMS and telephone calls,
                provide you with marketing from other third parties, customise
                any third party advertising that may be visible to you on third
                party websites, user your geo-location to provide you with
                location based services and use any sensitive personal
                information to make transactions smoother.
              </p>
              <p>
                Please note you have the right to withdraw your consent at any
                time.
              </p>
              <h3>
                Your Choices About How We Can Use Your Personal Information
              </h3>
              <h5>Marketing</h5>
              <p>
                If you no longer want to receive any marketing communications
                from us, you have the option to unsubscribe via the link at the
                bottom of any emails you receive. From there you can change your
                communication preferences.
              </p>
              <p>
                We do not sell or disclose any personal information to third
                parties for their own marketing purposes without asking for your
                consent.
              </p>
              <h5>Advertising</h5>
              <p>
                If you no longer want to participate in our advertising
                programmes, you can opt-out by following the directions enclosed
                with the advertisement, or via the programmes which are noted in
                our user cookie notice. After you-opt out, personalised
                advertising will stop, but it will still allow your personal
                information to be collected, as described in this privacy
                policy.
              </p>
              <p>
                We do not allow any third parties to collect or track personal
                information from our sites for their own advertising purposes
                without content.
              </p>
              <h3>
                Ways You Can Access, Control and Correct Your Personal
                Information
              </h3>
              <p>
                UK Travel Route will honour any rights you may have to access,
                change or delete your personal information stored by us. To
                request access and to find out if any fees apply, if permitted
                by applicable laws, please click on the contact us page below
                and follow the necessary instructions. In cases where you have a
                right to request access or request the modification or deletion
                of any personal information, we can withhold the access or
                decline to change or delete your personal information in certain
                cases, in accordance with applicable laws.
              </p>
              <p>
                If you request us to stop processing your personal information,
                or you withdraw your consent for our use to disclose any
                personal information for any purposes outlined in this privacy
                policy, we may not be able to provide you with all of the
                customer service and support offered to our users.
              </p>
              <h3>How We Might Share Your Personal Information</h3>
              <p>
                We may disclose personal information to financial institution
                partners, third party service providers, law enforcement or
                government agencies and credit agencies or bureaus authorised by
                law for the following purposes:
              </p>
              <p>&nbsp;</p>
              <p>
                . To provide joint services and content (i.e. customer support
                and transactions)
              </p>
              <p>
                . To help us detect, prevent and alleviate any potential fraud
                or illegal acts and violations of our data security
              </p>
              <p>
                . To send you personalised advertising and marketing
                communications
              </p>
              <p>. To improve products, services, tools and marketing</p>
              <p>
                . To help us provide our customer services and payment
                processing services
              </p>
              <p>. To offer you financial products</p>
              <p>. To comply with our legal requirements</p>
              <p>
                . To respond to claims that any content violates the rights of
                others
              </p>
              <p>. To protect an individual’s rights and safety</p>
              <p>
                . In response to legal process relating to criminal
                investigations or alleged illegal activity (we only disclose
                information deemed relevant to the investigation with details
                such as your name, address, telephone number, user ID history,
                IP address, email address and any fraud complaints)
              </p>
              <p>
                . For information on missed or late payments which may be
                implemented onto your credit report
              </p>
              <p>
                . In order to prevent any financial loss or imminent physical
                harm
              </p>
              <p>. To report any suspected illegal activity</p>
              <h3>Change of Ownership</h3>
              <p>
                In the unlikely event that we are subject to merge with another
                company or change of ownership, we may share information with
                them (in accordance with international privacy standards). We
                will require that the new organisation will follow this privacy
                notice to respect any personal information given. If we handle
                your personal information for any purposes not included in this
                policy, you will receive a notification prior to the processing
                of this information for the new purposes.
              </p>
              <h3>How Long We Keep Your Personal Information</h3>
              <h5>Advertising</h5>
              <p>
                How long we keep any personal information can vary depending on
                the context of the service we provide, and also on our legal
                obligations. The below factors influence the period in which
                your personal information is retained:
              </p>
              <p>
                . How long the personal information is needed to provide our
                services
              </p>
              <p>. Improving and maintaining the performance of our products</p>
              <p>. Keeping our website and systems secure</p>
              <p>
                . Maintaining any appropriate financial and business records
              </p>
              <p>
                . A shortened data retention time will apply for sensitive
                personal information
              </p>
              <p>. If you have provided consent for a longer period</p>
              <p>
                . We are subject to contractual or legal obligations to retain
                personal information, for example mandatory data retention laws
                and government orders to preserve data which is relevant to any
                investigations
              </p>
              <p>
                . Personal information may be retained for litigation purposes
              </p>
              <p>
                The above are general factors that establish a baseline for our
                data retention.
              </p>
              <p>
                After these exceptions, it will be no longer necessary for us to
                keep your personal information. We will dispose of the data
                securely according to our deletion and data policies.
              </p>
              <h3>Internet cookies</h3>
              <p>
                We use cookies on this website to provide you with a better user
                experience. We do this by placing a small text file on your
                device / computer hard drive to track how you use the website,
                to record or log whether you have seen particular messages that
                we display, to keep you logged into the website where
                applicable, to display relevant adverts or content, referred you
                to a third party website.
              </p>
              <p>
                Some cookies are required to enjoy and use the full
                functionality of this website.
              </p>
              <p>
                We use a cookie control system which allows you to accept the
                use of cookies, and control which cookies are saved to your
                device / computer. Some cookies will be saved for specific time
                periods, where others may last indefinitely. Your web browser
                should provide you with the controls to manage and delete
                cookies from your device, please see your web browser options.
              </p>
              <p>
                If you have any questions regarding this policy, or you wish to
                update your details, please inform us using the&nbsp;
                <strong>Contact Us</strong> facility on our Site. Thank you for
                visiting the UK Travel Route
              </p>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

      </>
    )
  }
}

export default PrivacyPolicy;
