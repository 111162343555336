import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from "../images/72cea1c1-0e95-4ed2-9778-c854b4e4899f-copy.jpg";
class Header extends Component {
  render() {
    return (
<>
<div
    data-elementor-type="header"
    data-elementor-id={161}
    className="elementor elementor-161 elementor-location-header"
    data-elementor-post-type="elementor_library"
  >
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-52593114 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id={52593114}
      data-element_type="section"
      data-settings='{"background_background":"gradient"}'
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-732ed9d2"
          data-id="732ed9d2"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-64bf395c elementor-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
              data-id="64bf395c"
              data-element_type="widget"
              data-widget_type="icon-list.default"
            >
              <div className="elementor-widget-container">
                <ul className="elementor-icon-list-items">
                  <li className="elementor-icon-list-item">
                    <a href="#">
                      <span className="elementor-icon-list-icon">
                        <svg
                          aria-hidden="true"
                          className="e-font-icon-svg e-fas-envelope"
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z" />
                        </svg>{" "}
                      </span>
                      <span className="elementor-icon-list-text">
                        admin@uktravelroute.co.uk
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4a6fa710"
          data-id="4a6fa710"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-2217b677 elementor-align-right elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
              data-id="2217b677"
              data-element_type="widget"
              data-widget_type="icon-list.default"
            >
              <div className="elementor-widget-container">
                <ul className="elementor-icon-list-items">
                  <li className="elementor-icon-list-item">
                    <a href="#">
                      <span className="elementor-icon-list-icon">
                        <i aria-hidden="true" className="icon icon-phone1" />{" "}
                      </span>
                      <span className="elementor-icon-list-text">
                        0203 983 7173
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-63786e5f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="63786e5f"
      data-element_type="section"
      data-settings='{"background_background":"classic"}'
    >
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-49aa43eb"
          data-id="49aa43eb"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-3bb29277 elementor-widget elementor-widget-image"
              data-id="3bb29277"
              data-element_type="widget"
              data-widget_type="image.default"
            >
              <div className="elementor-widget-container">
              <Link to="/">
                  <img
                    fetchpriority="high"
                    width={461}
                    height={267}
                    src={logo}
                    className="attachment-full size-full wp-image-162"
                    alt=""
                    //srcSet="https://uktravelroute.co.uk/wp-content/uploads/2024/09/72cea1c1-0e95-4ed2-9778-c854b4e4899f-copy.jpg 461w, https://uktravelroute.co.uk/wp-content/uploads/2024/09/72cea1c1-0e95-4ed2-9778-c854b4e4899f-copy-300x174.jpg 300w"
                    sizes="(max-width: 461px) 100vw, 461px"
                  />{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-32eea1ab"
          data-id="32eea1ab"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-22a51f93 elementor-widget elementor-widget-ekit-nav-menu"
              data-id="22a51f93"
              data-element_type="widget"
              data-widget_type="ekit-nav-menu.default"
            >
              <div className="elementor-widget-container">
                <div
                  className="ekit-wid-con ekit_menu_responsive_tablet"
                  data-hamburger-icon=""
                  data-hamburger-icon-type="icon"
                  data-responsive-breakpoint={1024}
                >
                  <button
                    className="elementskit-menu-hamburger elementskit-menu-toggler"
                    type="button"
                    aria-label="hamburger-icon"
                  >
                    <span className="elementskit-menu-hamburger-icon" />
                    <span className="elementskit-menu-hamburger-icon" />
                    <span className="elementskit-menu-hamburger-icon" />
                  </button>
                  <div
                    id="ekit-megamenu-main-menu"
                    className="elementskit-menu-container elementskit-menu-offcanvas-elements elementskit-navbar-nav-default ekit-nav-menu-one-page- ekit-nav-dropdown-hover"
                  >
                    <ul
                      id="menu-main-menu"
                      className="elementskit-navbar-nav elementskit-menu-po-right submenu-click-on-icon"
                    >
                      <li
                        id="menu-item-166"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home page_item page-item-107 current_page_item menu-item-166 nav-item elementskit-mobile-builder-content active"
                        data-vertical-menu="750px"
                      >
                        <Link to="/">Home</Link>
                        {/* <a
                          href="index.html"
                          className="ekit-menu-nav-link active"
                        >
                          Home
                        </a> */}
                      </li>
                      <li
                        id="menu-item-273"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-273 nav-item elementskit-mobile-builder-content"
                        data-vertical-menu="750px"
                      >
                        <Link to="/about">About</Link>
                        {/* <a
                          href="about-us/index.html"
                          className="ekit-menu-nav-link"
                        >
                          About Us
                        </a> */}
                      </li>
                      <li
                        id="menu-item-272"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-272 nav-item elementskit-mobile-builder-content"
                        data-vertical-menu="750px"
                      >
                        <Link to="/contact">Contact</Link>
                        {/* <a
                          href="contact-us/index.html"
                          className="ekit-menu-nav-link"
                        >
                          Contact Us
                        </a> */}
                      </li>
                    </ul>
                    <div className="elementskit-nav-identity-panel">
                      <div className="elementskit-site-title">
                        <a
                          className="elementskit-nav-logo"
                          href="index.html"
                          target="_self"
                          rel=""
                        >
                          <img src="#" title="" alt="" />
                        </a>
                      </div>
                      <button
                        className="elementskit-menu-close elementskit-menu-toggler"
                        type="button"
                      >
                        X
                      </button>
                    </div>
                  </div>
                  <div className="elementskit-menu-overlay elementskit-menu-offcanvas-elements elementskit-menu-toggler ekit-nav-menu--overlay" />{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

</>
      
    );
  }
}

export default Header;
